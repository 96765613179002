import React, { useEffect, useState } from "react";
import {
  clientIcon,
  coldIcon,
  depositIcon,
  payoutIcon,
  teamIcon,
} from "../../utils/historyIcons/historyIcons";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getTopCustomersByCompanyAndCategory } from "../../services/customers/services";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const Home = () => {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const [topCustomerData, setTopCustomerData] = useState({
    labels: ["Customers"],
    datasets: [],
  });
  const colors = [
    "rgba(75, 192, 192, 1)", // Teal
    "rgba(54, 162, 235, 1)", // Blue
    "rgba(255, 206, 86, 1)", // Yellow
    "rgba(153, 102, 255, 1)", // Purple
    "rgba(103, 224, 104, 1)", // Green
    "rgba(201, 203, 207, 1)", // Light Gray
    "rgba(255, 159, 64, 1)", // Orange
    "rgba(255, 99, 132, 1)", // Pinkish Red
    "rgba(90, 90, 231, 1)", // Deep Blue
    "rgba(245, 66, 152, 1)", // Bright Pink
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: "white", // Set legend text color to white
        },
        position: "top",
      },
      title: {
        display: true,
        text: "Top 10 customers",
        color: "white", // Set title text color to white
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white", // Set x-axis tick labels to white
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)", // Optional: Light white grid lines
        },
      },
      y: {
        ticks: {
          color: "white", // Set y-axis tick labels to white
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)", // Optional: Light white grid lines
        },
      },
    },
  };

  const getCustomers = React.useCallback(async () => {
    const data = await getTopCustomersByCompanyAndCategory(user, company.id);
    const customers = data?.customers;

    setTopCustomerData((state) => {
      return {
        ...state,
        datasets: customers.map((customer, index) => ({
          label: customer.fullName,
          data: [customer.totalDeposits],
          backgroundColor: colors[index],
          borderColor: colors[index],
          borderWidth: 1,
        })),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id, user]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <div className="container lg:py-3 max-w-screen-lg ">
      <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3  ">
        <a
          href="/clients"
          className="relative block order-last max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 "
        >
          <div className="">
            <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">
              Customer
            </h5>
            <p className="font-normal dark:text-gray-400 ">
              Administrate your customers
            </p>
            <div className="absolute inset-y-3 right-3">{clientIcon()}</div>
          </div>
        </a>

        <a
          href="/team"
          className="relative block max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 hover:stroke-white "
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">
            Team
          </h5>
          <p className="font-normal dark:text-gray-400">See and manage teams</p>
          <div className="absolute inset-y-3 right-3 hover:text-white">
            {teamIcon()}
          </div>
        </a>

        <a
          href="/clients/cold"
          className="relative block max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 "
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Cold Accounts
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Filter inactive accounts
          </p>
          <div className="absolute inset-y-3 right-3">{coldIcon()}</div>
        </a>
        <a
          href="/clients/deposits"
          className="hidden relative max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 "
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Deposit
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            description
          </p>
          <div className="absolute inset-y-3 right-3">{depositIcon}</div>
        </a>
        <a
          href="/clients/payments"
          className="relative hidden max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 "
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Payouts
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            description
          </p>
          <div className="absolute inset-y-3 right-3">{payoutIcon()}</div>
        </a>
      </div>
      <div className="grid w-full gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
        <div className="col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-4">
          <Bar data={topCustomerData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Home;
