import { singOut } from "../../store/slices/userSlice";
import store from "../../store/store";

export const getCustomers = async (
  user,
  companyId,
  page,
  search,
  category,
  communications,
  pageSize = 10
) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}customer/getCustomers?companyId=${companyId}&page=${page}&pageSize=${pageSize}&search=${search}&category=${category}&communications=${communications}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    customers: json?.customers ?? [],
    totalCustomers: json?.totalRecords ?? 0,
  };
};

export const getTopCustomersByCompanyAndCategory = async (user, companyId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}customer/getTopCustomersByCompanyAndCategory?companyId=${companyId}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    customers: json ?? [],
  };
};

export const getCustomerById = async (user, customerId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}customer/getCustomer?customerId=${customerId}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    customer: json,
  };
};

export const updateCustomerById = async (user, customerId, data) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const dataWithOutId = { ...data };
  delete dataWithOutId._id;
  delete dataWithOutId.companyId;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      customerId,
      data: dataWithOutId, // Spread the data object into the body
    }),
  };

  const response = await fetch(
    `${baseApi}customer/updateCustomer`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    customer: json,
  };
};

export const newCustomer = async (user, data) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      data, // Spread the data object into the body
    }),
  };

  const response = await fetch(`${baseApi}customer/newCustomer`, options).then(
    (response) => response.json()
  );

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    customer: json,
  };
};

export const newCustomers = async (user, data, companyId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      data, // Spread the data object into the body
      companyId,
    }),
  };

  const response = await fetch(`${baseApi}customer/newCustomers`, options).then(
    (response) => response.json()
  );

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    customers: json,
  };
};
